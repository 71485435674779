import React, { PureComponent } from "react"
import Layout from '../components/layout';
import Homepage from '../components/homepage';

export default class IndexPage extends PureComponent {
  render() {
    return (
      <Layout>
        <Homepage/>
      </Layout>
    )
  }
}
