import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

class ExpandableContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggle = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { expanded } = this.state;
    const { always, hidden } = this.props;
    return (
      <div className='expandable-content'>
        {always && (
        <div className='expandable-show'>
          {always()}
        </div>
        )}
        <div className={expanded ? 'expandable-show' : 'expandable-hidden'}>
          {hidden()}
        </div>
        <div className='collapser'>
          {expanded ? (
            <button onClick={this.toggle}>Read Less <FontAwesomeIcon fixedWidth icon={faChevronUp}/></button>
          ) : (
            <button onClick={this.toggle}>Read More <FontAwesomeIcon fixedWidth icon={faChevronDown}/></button>
          )}
        </div>
      </div>
    );
  }
}

export default ExpandableContent;