import React, { Fragment, PureComponent } from "react"
import { Row, Col } from 'react-bootstrap';
import RaisedButton from "./rasied-button";
import ExpandableContent from './expandable-content';
import StrategyFigure from './strategy-figure';
import PersonalMessageRow from './personal-message-row';
import ToniStanding from '../images/toni-standing.png';
import ResetBook from '../images/reset.png';
import CoachingImg from '../images/ideas.svg';
import CoursesImg from '../images/online-course.svg';
import PresentationsImg from '../images/presentation.svg';

export default class HomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      particleRowHeight: '100%'
    }
  }

  setParticleRowHeight = height => {
    this.setState({ particleRowHeight: height });
  }

  render() {
    const { stretchRowStyles } = this.props;
    return (
      <Fragment>
        <div className="book-banner row" style={stretchRowStyles}>
          <div className="banner-inner">
            <div className="banner-section">
              <img className='banner-photo' src={ResetBook} alt="The Reset by Dr. Toni Warner" />
            </div>
            <div className="banner-section">
                <p className="banner-text">
                  The Reset, A High Achiever’s Guide to Freedom and Fulfillment: Your Step-By-Step Roadmap for Getting Unstuck
                </p>
                <RaisedButton href='https://amazon.com/dp/1954047479'>
                  Buy it on Amazon
                </RaisedButton>
            </div>
          </div>
        </div>
        <Row className="intro-row justify-content-md-center">
          <p className='framework-header'>
                Start Maximizing Your Personal & Professional Potential Today
          </p>
          <Col md={8}>
          <div className="intro-hero">
            <div className="intro-hero-text">
              <p>
                Ready to take the steps necessary to find balance and enhance your life?
              </p>
            </div>
            <div>
              <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
                Schedule A Consultation
              </RaisedButton>
            </div>
          </div>
          </Col>
          <Col className='intro-photo-col' md={4}>
            <img className='intro-photo' src={ToniStanding} alt="Dr. Toni Warner" />
          </Col>
        </Row>
        <Row className='featured-in-row' style={stretchRowStyles}>
          <p>
            Why choose between qualifications and experience when you can have both?
          </p>
        </Row>
        <Row className="framework-row justify-content-md-center">
          <p className='framework-header'>
            Bridge The Gap Between You and Optimal Living
          </p>
          <p className='framework-text'>
            High-achieving leaders, professionals and businesses can only make maximum impact without burning out, when they have the qualified guidance and essential framework to optimize their lifestyle and wellness effectively.
          </p>
        </Row>
        <Row className="strategy-row justify-content-md-center">
          <Col sm={4}>
            <StrategyFigure
              img={CoachingImg}
              header='High-Touch Custom Coaching'
            >
              1:1 coaching puts Dr. Toni in your back pocket. The most comprehensive and individualized package available, Dr. Toni will provide the personalized experience that maximizes your desired results! Direct coaching sessions, e-materials, and in-between session support; this opportunity is limited in availability and is accessible by application only.
            </StrategyFigure>
          </Col>
          <Col sm={4}>
            <StrategyFigure
              img={CoursesImg}
              header='Courses'
            >
              Self-paced coaching courses teach you how to navigate common areas of challenge, like time management, high stress levels and motivation. All courses are hand-crafted by Dr. Toni and exclusive to Bold & Balanced Coaching; they can’t be found anywhere else. Access is quick and convenient.
            </StrategyFigure>
          </Col>
          <Col sm={4}>
            <StrategyFigure
              img={PresentationsImg}
              header='Presentations'
            >
              A powerful way to educate and support your team with burnout, decision-making, confidence, or balancing work and life. With passion and impact, Dr. Toni marries the practical tools and latest research to help your team enhance their wellness, while boosting productivity and performance.
            </StrategyFigure>
          </Col>
        </Row>
        <Row className='info-row-1'>
          <Col sm={2} />
          <Col sm={8}>
            <div className='four-elements'>
              <h1>4 Required Elements For Optimization</h1>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/skM6gyAxsJY?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="4 Key Elements for Self Optimization Information Inspiration Integration Transformation"/>
            </div>
          </Col>
          <Col sm={2} />
        </Row>
        <Row className='expandable-info-row justify-content-md-center info-row-2' style={stretchRowStyles}>
          <Col sm={2} />
          <Col sm={8}>
            <div>
              <h2>How does Dr. Toni’s coaching benefit me as a leader?</h2>
              <ExpandableContent
               always={() => {
                 return (
                   <Fragment>
                    <p>
                      If you are a leader, at work, at home, or both, then you care about your impact, you care about your productivity, and you care about the quality of relationships in your life. The challenge is that the most caring and passionate leaders tend to exhaust themselves in an effort to meet all of the needs and demands of their many roles and responsibilities.
                    </p>
                   </Fragment>
                 );
               }}
               hidden={() => {
                 return (
                   <Fragment>
                    <p>
                      The long-term result of living a lifestyle like this is typically some form of chronic stress, anxiety and/or burn out. When any of these happens, it’s no longer possible to show up for the things you care about, in the way they deserve, nor the way you want to.
                    </p>
                    <p>
                      Productivity suffers. Relationships suffer. Impact and creativity suffer. Your health and happiness suffers.
                    </p>
                    <p>
                      The benefit of working with Dr. Toni as a life coach, is that each of these important areas of your life can and will be enhanced through your work together.
                    </p>
                    <p>
                      Despite studying and practicing in the field of human behavior and psychology for over a decade, Dr. Toni herself has navigated burnout, toxic work environments, relationship issues and mental health challenges. This means that you don’t need to choose between someone who personally “gets it” and someone who is professionally qualified. Dr. Toni provides professional quality support with personalized passion and accountability.
                    </p>
                    <p>
                      As a leader, the more you develop yourself personally, the more your professional performance benefits as well. An investment in your personal development is an investment in your professional development. It is not uncommon at all, for coaching clients to increase their income or grow in their careers, while engaging in coaching with Dr. Toni.
                    </p>
                   </Fragment>
                 );
               }}
              />
              <div>
              </div>
            </div>
            <div>
              <h2>How does Dr. Toni’s coaching benefit businesses and teams?</h2>
              <ExpandableContent
              always={() => {
                return (
                  <Fragment>
                    <p>
                      As an impact-driven business, you care about both the bottom line and your teams. However, the pressure to keep up the bottom line can often have negative effects on the wellness of your team, which directly impacts your business.
                    </p>
                  </Fragment>
              )}}
              hidden={() => {
                return (
                  <Fragment>
                    <p>
                      Dr. Toni has worked in many environments, such as school districts, hospitals and community organizations. A key role she assumed in nearly all of those places, was to help bridge the gap between those that lead, and those being led. When the gap is too great, it becomes filled with frustration, lack of communication and resentment. Ultimately, this fosters an unpleasant work environment at the least, and a toxic work environment at its worst.
                    </p>
                    <p>
                      Dr. Toni has become practiced at the skill of bridging and building communication among key contributors, leaders and team members, ultimately enhancing the quality of interactions and therefore efficacy and productivity of outcomes.
                    </p>
                    <p>
                      A major part of this work requires understanding and addressing life balance and wellness among staff and leaders. Dr. Toni utilizes her professional skills as an experienced clinician, coach and behavior specialist, as well as her personal experiences within systems and with leadership, to customize a program that meets the needs of your and their teams.
                    </p>
                    <p>
                      An investment in business wellness with Dr. Toni, is an investment in your bottom line as well as your human capital.
                    </p>
                  </Fragment>
              )}}
              />
            </div>
            <div className='schedule-button'>
              <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
                Schedule Your Free Consultation
              </RaisedButton>
            </div>
          </Col>
          <Col sm={2} />
        </Row>
        <PersonalMessageRow/>
        <Row className='call-row' style={stretchRowStyles}>
          <div className='call-row-content'>
            <p>
              So, you’ve got a ton of responsibilities that pull you in a million different directions. You’re used to doing well and getting things done, but you’ve noticed there’s more stress, more irritation and more brain fog. 
            </p>
            <p>
              You want to get off the hamster wheel without jeopardizing your ambition or productivity.
            </p>
            <p className='call-row-main-text'>
              Take a free clarity assessment to help you determine your personal wellness grade, and find out what next steps to take to stop the cycle and feel more free.
            </p>
            <p>
              Assess honestly, and where it leads can change your life!
            </p>
            <div className='btn-wrapper'>
              <RaisedButton href='https://courses.boldandbalancedcoaching.com/products/courses/view/6'>
                Grab Your Free Clarity Assessment
              </RaisedButton>
            </div>
          </div>
        </Row>
      </Fragment>
    )
  }
}
