import React from 'react';

const StrategyFigure = ({img, alt, header, children}) => {
  return (
    <div className='strategy-figure'>
      <img src={img} alt={alt} loading="lazy"/>
      <p className='strategy-header text-cream'>{header}</p>
      <p>
        {children}
      </p>
    </div>
  );
}

export default StrategyFigure;